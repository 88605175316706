<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="credentialsGrid"
          ref="credentialsGridRef"
          :data-source="credentialsDataSource"
          :height="setHeight"
          :cache-enabled="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
        >
          <!--region    ::DataGird base components -->
            <dx-sorting mode="single" />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
          <!--endregion ::DataGird base components -->
          <dx-column
            data-field="accountNo"
            caption="Acc #"
            width="auto"
            alignment="left"
            css-class="vertical-middle"
          />
          <!--endregion ::DataGird base components -->
          <dx-column
            data-field="suiteNo"
            caption="Suite #"
            width="auto"
            alignment="left"
            css-class="vertical-middle"
          />
          <dx-column data-field="companyName" css-class="vertical-middle" width="auto" />
          <dx-column data-field="permissionName" css-class="vertical-middle" width="auto" />
          <dx-column data-field="username" css-class="vertical-middle" width="auto" />
          <dx-column data-field="password" cell-template="tempPassword" css-class="vertical-middle" width="20%" />
          <template #tempPassword="{ data }">
            <password-hider :password="data.value" />
          </template>
          <dx-column data-field="link" cell-template="tempLink" css-class="vertical-middle" width="auto" />
          <template #tempLink="{ data }">
            <a v-if="data.value" :href="data.value" target="_blank">
              <span class="text-capitalize d-block badge badge-light-info badge-pill">
                Link
                <feather-icon class="ml-1" icon="ExternalLinkIcon" size="18" />
              </span>
            </a>
          </template>
          <dx-column data-field="notes" css-class="vertical-middle" />
          <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div>
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="getDataByAccount"
                />
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { credentialsDataSource } from './credentialsStore'

export default {
  components: {
    'password-hider': () => import('./CredentialsHider.vue'),
  },
  mixins: [GridBase],
  data: () => ({
    credentialsDataSource,
    accountNo: '',
  }),
  computed: {
    dataGrid() {
      const gridIns = this.$refs.credentialsGridRef.instance
      return gridIns
    },
  },
  watch: {
    accountNo(current) {
      if (!current) {
        this.searchByFilter()
      }
    },
  },
  mounted() {
    this.initialLoad()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reload()
          },
        },
        location: 'after',
      })
    },
    initialLoad() {
      const filters = {
        accountNo: '0',
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reload() {
      const filters = {
        accountNo: '0',
      }
      this.accountNo = ''
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    searchByFilter() {
      const accountNo = this.accountNo ? this.accountNo : '0'
      const filters = {
        accountNo: accountNo,
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    getDataByAccount(e) {
      if (e.event.keyCode !== 13) return
      const currentAccountNo = e.event.target.value
      this.accountNo = currentAccountNo
      this.searchByFilter()
    },
  },
}
</script>
<style lang="scss">
#credentialsGrid {
  .vertical-middle {
    vertical-align: middle !important;
  }
}
</style>
